import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="text-center">
      ⁴୦₄ <b>Not Found</b>
    </div>
  </Layout>
)

export default NotFoundPage
